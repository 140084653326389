<template>
  <div>
    <p class="font-barlow font-bold text-xl text-grey-dark-2 mb-3">
      Assessments
    </p>
    <DraftAssessments :extended="true" />
    <div class="bg-white rounded shadow pt-3 px-2 pb-5 mb-3">
      <div class="flex flex-row justify-between">
        <p class="font-barlow font-bold text-mdl text-grey-light ">
          Assessments Library
        </p>
        <div class="mr-2.5">
          <SubscriptionStatusBox :subStatus="status" />
        </div>
      </div>
      <div class="mx-2">
        <AssessmentCardsContainer
          v-for="assessments in allAssessments"
          :title="assessments.name"
          :cardsCount="assessments.surveys.length"
        >
          <NewAssessmentCard
            v-for="assessment in assessments.surveys"
            :key="assessment.title"
            :survey="assessment"
            :available="assessment.available"
            :title="assessment.title"
            class="flex-shrink-0"
          />
        </AssessmentCardsContainer>
      </div>
    </div>
  </div>
</template>

<script>
import NewAssessmentCard from "@/components/NewAssessmentCard/NewAssessmentCard.vue";
import DraftAssessments from "../../Home/components/DraftAssessments";
import SubscriptionStatusBox from "../../../components/SubscriptionStatusBox/SubscriptionStatusBox.vue";
import AssessmentCardsContainer from "../components/AssessmentCardsContainer.vue";

import { mapActions, mapState } from "vuex";
export default {
  components: {
    NewAssessmentCard,
    DraftAssessments,
    SubscriptionStatusBox,
    AssessmentCardsContainer,
  },
  computed: {
    ...mapState({
      availableSurveys: (state) => state.assessmentSetup.availableSurveys,
      subDetails: (state) => state.subscriptions.subscriptionDetails,
      companyId: (state) => state.auth.decodedData["organization-id"],
      impersonatedCompanyId: (state) =>
        state.auth.impersonateDecodedData["organization-id"],
    }),
    allAssessments() {
      return [
        { name: "Individual", surveys: this.individualAssessments },
        { name: "Organization", surveys: this.organizationalAssessments },
      ];
    },
    individualAssessments() {
      return this.availableSurveys
        .filter((item) => item.surveyType === 0)
        .sort((a, b) => b.availableInSubscription - a.availableInSubscription);
    },
    organizationalAssessments() {
      return this.availableSurveys
        .filter((item) => item.surveyType === 1)
        .sort((a, b) => b.availableInSubscription - a.availableInSubscription);
    },
    compId() {
      return this.companyId ? this.companyId : this.impersonatedCompanyId;
    },
    status() {
      return this.subDetails?.subscriptionStatus;
    },
  },
  methods: {
    ...mapActions({
      getDrafts: "assessmentSetup/getDrafts",
      fetchAvailableSurveys: "assessmentSetup/fetchAvailableSurveys",
      getSubscriptionDetails: "subscriptions/getSubscriptionDetails",
    }),
  },
  name: "Assessments",
  async mounted() {
    await this.getSubscriptionDetails(this.compId);
    await this.fetchAvailableSurveys();
    this.getDrafts();
  },
};
</script>
